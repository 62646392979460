/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import initVerleih from './modules/verleih'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import debug from './modules/debug'
import Swiper from 'swiper'
/* import Pikaday from 'pikaday'*/
/**
 * Ab geht die Reise
 */
domready(() => {
  initToggleSubMenu()
  categoryInit()
  initModal()
  initVerleih()
  formSubmit()
  initMaps()
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  // eslint-disable-next-line
  new Swiper('#slider', {
    effect: 'fade',
    fade: {
      crossFade: false
    },
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: false,
    pagination: '.swiper-pagination',
    paginationClickable: true
  })

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
  debug()
  // eslint-disable-next-line
  /*new Pikaday({
    field: document.getElementById('anDate'),
    format: 'DD.MM.YYYY',
    position: 'top center',
    onSelect: function () {
      console.log(this.getMoment().format('DD.MM.YYYY'))
    }
  })*/
// eslint-disable-next-line
})
